import { Global, css } from '@emotion/react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { ComponentPropsWithoutRef, Fragment } from 'react'
import { useInView } from 'react-intersection-observer'

import { useNavContext } from '@/features/layout'
import { DatoLink } from '@/features/links'
import { LogoStacked } from '@/features/logo'
import { absoluteFill, bezier, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import MobileNavButton from './MobileNavButton'
import NavNetworkGroup from './NavNetworkGroup'

const Nav = ({
  ...props
}: ComponentPropsWithoutRef<'nav'>): JSX.Element => {
  const { datoCmsNav: data } = useStaticQuery<Queries.NavQuery>(graphql`
    query Nav {
      datoCmsNav {
        links {
          ... on DatoCmsPageLink {
            ...PageLink
          }
          ... on DatoCmsExternalLink {
            ...ExternalLink
          }
          ... on DatoCmsNavNetworkGroup {
            ...NavNetworkGroup
          }
        }
        button {
          ... on DatoCmsPageLink {
            ...PageLink
          }
          ... on DatoCmsExternalLink {
            ...ExternalLink
          }
          ... on DatoCmsFormLink {
            ...FormLink
          }
        }
        breakpoint
      }
    }
  `)
  const { inView: observerInView, ref: observerRef } = useInView({
    initialInView: true,
  })
  const isScrolled = !observerInView
  const { mobileNavIsOpen, setMobileNavIsOpen } = useNavContext()

  const isLinkCurrentPage = (path?: string | null) => {
    if (typeof window !== 'undefined') {
      return window.location.pathname === `/${path}/`
    }
  }
  const styles = {
    global: css`
      :root {
        --nav-height: calc(var(--fs-48) * 2);
        --nav-height-min: calc(var(--fs-36) * 1.5);
        --nav-height-flex: var(--nav-height);
        ${isScrolled &&
        css`
          --nav-height-flex: var(--nav-height-min);
        `}
      }
    `,
    scrollObserver: css`
      position: relative;
      height: var(--nav-height);
      width: 100%;
      pointer-events: none;
    `,
    nav: css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      display: flex;
      justify-content: space-between;
      color: #fff;
      &:before {
        content: '';
        ${absoluteFill}
        background: ${colors.tealDark};
        z-index: 9;
      }
    `,
    logo: css`
      position: relative;
      font-size: var(--fs-48);
      background: ${colors.teal};
      padding: 0.5em 1em;
      display: flex;
      z-index: 10;
      transition: all 300ms ease;
      svg {
        display: flex;
        height: 1em;
        min-height: 100%;
      }
      ${isScrolled &&
      css`
        font-size: var(--fs-36);
        padding: 0.25em 1em;
      `}
      @media (hover: hover) {
        &:hover {
          background: ${colors.apricot};
        }
      }
    `,
    links: css`
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      column-gap: 2em;
      padding: 0 3em;
      box-sizing: border-box;
      font-size: var(--fs-16);
      z-index: 10;
      @media (max-width: ${data?.breakpoint}px) {
        ${mq().ms} {
          width: 100%;
        }
        position: fixed;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        display: block;
        box-sizing: border-box;
        padding-top: var(--nav-height-flex);
        overflow: scroll;
        background: ${colors.cream};
        color: ${colors.teal};
        transform: translate3d(100%, 0, 0);
        opacity: 0;
        pointer-events: none;
        transition:
          opacity 300ms ${bezier.easeIn} 200ms,
          transform 300ms ${bezier.easeIn};
        z-index: 8;
        ${mobileNavIsOpen &&
        css`
          transform: translate3d(0, 0, 0);
          opacity: 1;
          pointer-events: all;
          transition:
            opacity 500ms ${bezier.easeOut},
            transform 500ms ${bezier.easeOut};
        `};
      }
    `,
    linksWrapper: css`
      display: contents;
      @media (max-width: ${data?.breakpoint}px) {
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        text-align: center;
        padding: 2em 0;
        box-sizing: border-box;
      }
    `,
    link: css`
      margin: 0;
      padding: 0.5em 0;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      z-index: 1;
      @media (hover: hover) {
        &:hover {
          color: ${colors.tealLight};
        }
      }
      @media (max-width: ${data?.breakpoint}px) {
        margin: 1em 0;
        font-size: var(--fs-21);
        ${mq().ms} {
          font-size: var(--fs-18);
        }
        @media (hover: hover) {
          &:hover {
            color: ${colors.tealDark};
          }
        }
      }
    `,
    currentPageLink: css`
      border-bottom: 2px solid currentColor;
    `,
    button: css`
      color: ${colors.apricot};
      z-index: 10;
    `,
  }
  return (
    <Fragment>
      <Global styles={styles.global} />
      <div
        ref={observerRef}
        css={styles.scrollObserver}
      />
      <nav
        css={styles.nav}
        {...props}
      >
        <Link
          css={styles.logo}
          to="/"
          onClick={() => setMobileNavIsOpen(false)}
        >
          <LogoStacked fill={'#fff'} />
        </Link>
        <div css={styles.links}>
          <div css={styles.linksWrapper}>
            {data?.links?.map(link => {
              switch (link?.__typename) {
                case 'DatoCmsPageLink':
                case 'DatoCmsExternalLink': {
                  return (
                    <DatoLink
                      css={[
                        styles.link,
                        link?.__typename === 'DatoCmsPageLink' &&
                          isLinkCurrentPage(link.page?.slug) &&
                          styles.currentPageLink,
                      ]}
                      data={link}
                      key={link?.id}
                      onClick={() => setMobileNavIsOpen(false)}
                    />
                  )
                }
                case 'DatoCmsNavNetworkGroup': {
                  return (
                    <NavNetworkGroup
                      data={link}
                      key={link.id}
                      breakpoint={data.breakpoint}
                    />
                  )
                }
              }
            })}
            {data?.button?.map(link => (
              <DatoLink
                css={[
                  styles.link,
                  styles.button,
                  link?.__typename === 'DatoCmsPageLink' &&
                    isLinkCurrentPage(link.page?.slug) &&
                    styles.currentPageLink,
                ]}
                data={link}
                key={link?.id}
                onClick={() => setMobileNavIsOpen(false)}
              />
            ))}
          </div>
        </div>
        <MobileNavButton breakpoint={data?.breakpoint} />
      </nav>
    </Fragment>
  )
}

export default Nav
